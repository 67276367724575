<template>
  <div v-if="!loadingData">
    <CampusHeader
        @start-creating="startCreatingCampus"
        @campus-selected="selectCampus"
        :campuses="campuses"
        :can-select-year="true"
        :can-create="
        !!$store.getters['entities/user-permissions/find']('create campuses')
      "
        @change-tab="changeTab"
        :noCampusOverride="noCampuses"
        :activeTab="activeTab"
        :hide-tabs="true"
        :header-text="'Campus Admin'"
        :campus="active_campus"
        :header_tabs="header_tabs"
    />
    <section v-if="!noCampuses" class="section pt-4 box">
      <b-tabs expanded multiline  :destroy-on-hide="true"   @input="changeTab"  :class="{'hidden-tabs':header_tabs.length<2}"  v-model="activeTab">
        <b-tab-item label="Campus Information">
          <div class="columns is-centered ">
            <div class="column is-12">
              <CampusForm
                  @campus-deleted="deleteCampus"
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
                    'create campuses'
                  )
                "
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit campuses'
                  )
                "
                  :can-delete="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete campuses'
                  )
                "
                  :campus="active_campus"
                  :edit="true"
              />
            </div>
          </div>
        </b-tab-item>

        <b-tab-item v-if="!noCampuses" label="Manage Academics">
          <ManageAcademicsWrapper>
            <div class="column is-4">
              <PhasesPanel
                  :can-edit-level="true"
                  :can-create-level="!!$store.getters['entities/user-permissions/find']('create levels')"
                  :show-classes="
                  !!$store.getters['entities/user-permissions/find'](
                    'view homeclass'
                  )
                "
                  :can-delete-class="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete homeclass'
                  )
                "
                  :can-edit-class="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit homeclass'
                  )
                "
                  :can-create-class="
                  !!$store.getters['entities/user-permissions/find'](
                    'create homeclass'
                  )
                "
                  :canCreatePhase="
                  !!$store.getters['entities/user-permissions/find'](
                    'create phases'
                  )
                "
                  :canCreateStage="
                  !!$store.getters['entities/user-permissions/find'](
                    'create stages'
                  )
                "
                  :canEditStage="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit stages'
                  )
                "
                  :canDeletePhase="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete phases'
                  )
                "
                  :canDeleteStage="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete stages'
                  )
                "
                  :canEditPhase="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit phases'
                  )
                "
                  :canSelectPhase="false"
                  :active-campus-id="activeCampusId"
                  :active_campus="active_campus"
                  :selected_phase="selected_phase"
              />
            </div>
            <div class="column">
              <SubjectsPanel
                  :show-attendances="true"
                  :canCreateSubject="
                  !!$store.getters['entities/user-permissions/find'](
                    'create subjects'
                  )
                "
                  :canEditSubject="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit subjects'
                  )
                "
                  :canDeleteSubject="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete subjects'
                  )
                "
                  :canCreateExtramural="
                  !!$store.getters['entities/user-permissions/find'](
                    'create extramurals'
                  )
                "
                  :canEditExtramural="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit extramurals'
                  )
                "
                  :canDeleteExtramural="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete extramurals'
                  )
                "
                  :canViewExtramural="true"
                  :active-campus-id="activeCampusId"
              />
            </div>
          </ManageAcademicsWrapper>

        </b-tab-item>

        <b-tab-item v-if="!!$store.getters['entities/user-permissions/find']('view subjects')" label="Programmes">
          <div class=" columns">
            <div class="column is-12">
              <ProgrammesPanel
                  :can-create="false"
                  :can-edit="false"
                  :can-delete="false"
              ></ProgrammesPanel>
            </div>
          </div>

        </b-tab-item>

        <b-tab-item v-if="!!$store.getters['entities/user-permissions/find']('edit settings')">
          <div class="box columns">
            <div class="column is-12">
              <SettingsPanel
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
                    'create settings'
                  )
                "
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit settings'
                  )
                "
                  :can-delete="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete settings'
                  )
                "
              ></SettingsPanel>
            </div>
          </div>

        </b-tab-item>


        <b-tab-item v-if="!!$store.getters['entities/user-permissions/find']('edit services')" label="Manage Services">
          <div class=" columns">
            <div class="column is-12">
              <ServicesTable
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
                    'create services'
                  )
                "
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit services'
                  )
                "
                  :can-delete="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete services'
                  )
                "
              ></ServicesTable>
            </div>
          </div>

        </b-tab-item>
        <b-tab-item v-if="!!$store.getters['entities/user-permissions/find']('edit services')" label="Manage Packages">
          <div class=" columns">
            <div class="column is-12">
              <PackagesTable
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
                    'create services'
                  )
                "
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit services'
                  )
                "
                  :can-delete="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete services'
                  )
                "
              ></PackagesTable>
            </div>
          </div>

        </b-tab-item>
        <b-tab-item v-if="!!$store.getters['entities/user-permissions/find']('edit terms')" label="Manage Terms">
          <div class=" columns">
            <div class="column is-12">
              <TermsTable
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
                    'create terms'
                  )
                "
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit terms'
                  )
                "
                  :can-delete="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete terms'
                  )
                "
              ></TermsTable>
            </div>
          </div>

        </b-tab-item>
        <b-tab-item v-if="!!$store.getters['entities/user-permissions/find']('edit sports-houses')" label="Manage Sports Houses">
          <div class=" columns">
            <div class="column is-12">
              <SportsHousesTable
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
                    'create sports-houses'
                  )
                "
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
                    'edit sports-houses'
                  )
                "
                  :can-delete="
                  !!$store.getters['entities/user-permissions/find'](
                    'delete sports-houses'
                  )
                "
              ></SportsHousesTable>
            </div>
          </div>

        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import Campus from "@/models/Campus";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import {mapState} from "vuex";
import CampusForm from "@/components/panelled-dash/CampusForm";
import PhasesPanel from "@/components/panelled-dash/PhasesPanel";
import SubjectsPanel from "@/components/panelled-dash/SubjectsPanel";
import ServicesTable from "@/components/services/ServicesTable";
import TermsTable from "@/components/terms/TermsTable";
import PackagesTable from "@/components/services/PackagesTable";
import SportsHousesTable from "@/components/sportsHouses/SportsHousesTable";
import ProgrammesPanel from "@/components/programmes/ProgrammesPanel";
import SettingsPanel from "@/components/settings/SettingsPanel";
import ManageAcademicsWrapper from "@/views/campuses/ManageAcademicsWrapper";

export default {
  name: "Campuses.dashboard",
  components: {
    SubjectsPanel,
    PhasesPanel,
    CampusHeader,
    CampusForm,
    ServicesTable,
    TermsTable,
    PackagesTable,
    SportsHousesTable,
    ProgrammesPanel,
    SettingsPanel,
    ManageAcademicsWrapper
  },
  data() {
    return {
      loadingData: true,
      activeCampusId: null,
      noCampuses: false,
      activeTab: 0,
    };
  },
  methods: {
    useTab(role) {
      return this.header_tabs.some((tab) => tab.role === role);
    },
    goToTabName(tabName) {
      this.activeTab = this.header_tabs.findIndex(tab => {
        return tab.role === tabName
      })

      this.setQuery({tab: tabName})

    },
    deleteCampus(id) {
      this.loadingData = true;

      Campus.Delete(id)
          .then(() => {
            this.selectCampus(this.campuses[0].id);
            this.activeTab = 0;
            this.loadingData = false;
          })
          .catch((err) => {
            this.handleError(err)
            this.loadingData = false;
          });
    },

    startCreatingCampus() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete campuses"
          ),
          canCreate: !!this.$store.getters["entities/user-permissions/find"](
              "create campuses"
          ),
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit campuses"
          ),
        },
        component: CampusForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "campus-created": (id) => {
            this.selectCampus(id);
          },
        },
      });
    },
    selectCampus(id) {
      this.$store.dispatch("loader/show");
      this.$store.dispatch("campus/selectCampus", id);
      Campus.FetchById(id, ["phases"]).then(() => {
        this.loadingData = false;
        this.activeCampusId = id;
        this.$store.dispatch("loader/hide");
      });
    },

    changeTab(index) {
      this.$nextTick(() => {
        this.activeTab = index;

      })

      this.setQuery({tab: this.header_tabs[index].role})
    },
  },

  mounted() {
    this.$store.dispatch("loader/show");
    Campus.FetchAll({page: 1, limit: 100}).then((response) => {
      if (response.response.data.data.length > 0) {


        let campusId;
        if (this.$store.state.campus.selected_campus_id !== null) {
          campusId = parseInt(this.$store.state.campus.selected_campus_id);
        } else {
          campusId = response.response.data.data[0].id;
        }

        this.$store.dispatch("campus/selectCampus", campusId);
        Campus.FetchById(campusId, ["phases"]).then(() => {
          this.loadingData = false;
          this.activeCampusId = campusId;
          if (this.$route.query.tab) {
            if (this.useTab(this.$route.query.tab)) {
              this.goToTabName(this.$route.query.tab)
            }
          }
          this.$store.dispatch("loader/hide");
        });
      } else {
        this.noCampuses = true;
        this.loadingData = false;
        this.$store.dispatch("loader/hide");
      }
    }).catch(
        err => {
          this.$store.dispatch("loader/hide");

          if (err.response.status === 422) {
            this.handleError(err)
          } else {
            this.handleError(err)
          }
        }
    );
  },
  computed: {
    header_tabs() {

      let tabs = [{title: 'Campus Information', role: 'campus-information'},{title: 'Manage Academics', role: 'academics'},
        {title: 'Programmes', role: 'programmes'},
        ]
      if (this.$store.getters['entities/user-permissions/find']('edit settings')) {
        tabs.push({title: 'Global Settings', role: 'settings'})
      }
      if (this.$store.getters['entities/user-permissions/find']('edit services')) {
        tabs.push({title: 'Manage Services', role: 'services'})
        tabs.push({title: 'Manage Packages', role: 'packages'})
      }
      if (this.$store.getters['entities/user-permissions/find']('edit terms')) {
        tabs.push({title: 'Manage Terms', role: 'terms'})
      }
      if (this.$store.getters['entities/user-permissions/find']('edit sports-houses')) {
        tabs.push({title: 'Manage Sports Houses', role: 'sports-houses'})
      }
      return tabs
    },
    ...mapState("campus", [
      "edit_stage",
      "selected_stage",
      "selected_phase",
      "filter_phase",
    ]),
    active_campus() {
      return Campus.query()
          .whereId(this.activeCampusId)
          .with("phases")
          .with("head_user")
          .first();
    },
    campuses() {
      return Campus.query().orderBy("ordinality").get();
    },
  },
};
</script>

