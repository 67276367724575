<template>
  <div class="is-fullwidth">

    <section class="section">
      <!--      <div class="container">-->
      <!--        <b-collapse-->
      <!--            class="card has-boxshadow"-->
      <!--            animation="slide"-->
      <!--            aria-id="contentIdForA11y3">-->
      <!--          <template #trigger="props">-->
      <!--            <div-->
      <!--                class="card-header"-->
      <!--                role="button"-->
      <!--            >-->
      <!--              <p class="card-header-title">-->
      <!--                Physical Address-->
      <!--              </p>-->
      <!--              <a class="card-header-icon">-->
      <!--                <b-icon-->
      <!--                    :icon="props.open ? 'menu-down' : 'menu-up'">-->
      <!--                </b-icon>-->
      <!--              </a>-->
      <!--            </div>-->
      <!--          </template>-->

      <!--          <form @submit.prevent="submitPhysicalAddress">-->
      <!--            <div class="card-content">-->
      <!--              <b-field label="Country">-->
      <!--                <b-select required icon="map-marker"-->
      <!--                          expanded placeholder="Country" v-model="settingsObject.country.value">-->
      <!--                  <option v-for="country in countries" :key="country.id" :value="country.name">{{-->
      <!--                      country.name-->
      <!--                    }}-->
      <!--                  </option>-->
      <!--                </b-select>-->
      <!--              </b-field>-->
      <!--              <b-field label="Street">-->
      <!--                <b-input required autocomplete="off" icon="map-marker"-->
      <!--                         placeholder="Street" v-model="settingsObject.street.value">-->

      <!--                </b-input>-->
      <!--              </b-field>-->
      <!--              <b-field label="City">-->
      <!--                <b-input-->
      <!--                    autocomplete="off"-->
      <!--                    required-->
      <!--                    icon="map-marker"-->
      <!--                    v-model="settingsObject.city.value"-->
      <!--                    placeholder="City"-->
      <!--                />-->
      <!--              </b-field>-->
      <!--              <b-field label="State/Province">-->
      <!--                <b-input-->
      <!--                    autocomplete="off"-->
      <!--                    required-->
      <!--                    icon="map-marker"-->
      <!--                    v-model="settingsObject.state.value"-->
      <!--                    placeholder="State/Province"-->
      <!--                />-->
      <!--              </b-field>-->
      <!--              <b-field label="Postal Code">-->
      <!--                <b-input-->
      <!--                    autocomplete="off"-->
      <!--                    required-->
      <!--                    icon="map-marker"-->
      <!--                    v-model="settingsObject.postal_code.value"-->
      <!--                    placeholder="Postal Code"-->
      <!--                />-->
      <!--              </b-field>-->
      <!--            </div>-->
      <!--            <footer class="card-footer mb-4">-->
      <!--              <b-button native-type="submit" type="is-primary" class="card-footer-item">Save</b-button>-->
      <!--            </footer>-->
      <!--          </form>-->
      <!--        </b-collapse>-->
      <!--      </div>-->
      <div class="container">
        <b-collapse
            class="card has-boxshadow"
            animation="slide"
            aria-id="contentIdForA11y3">
          <template #trigger="props">
            <div
                class="card-header"
                role="button"
            >
              <p class="card-header-title">
                Contact Information
              </p>
              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
            </div>
          </template>

          <form @submit.prevent="submitContactInformation">
            <div class="card-content">
              <b-field label="Website">
                <b-input required autocomplete="off" icon="web"
                         placeholder="Website" v-model="settingsObject.website.value">

                </b-input>
              </b-field>
              <b-field label="Contact Number">
                <vue-tel-input v-if="settingsObject.contact_number.value.length>0"
                               :inputOptions="{required:false}" ref="primary_contact_number" :defaultCount="+27"
                               :autoFormat="false" :enabledCountryCode="true"
                               :mode="'international'"
                               class="input"
                               :invalidMsg="'Please enter a valid phone number'"
                               v-model="settingsObject.contact_number.value"></vue-tel-input>
                <b-input v-else
                         @input="keepTyping($event,'primary_contact_number')"

                         autocomplete="off"
                         v-model="settingsObject.contact_number.value"
                         name="phone"
                         type="tel"
                         validation-message="Please enter a valid phone number"
                         pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                         icon="phone"
                         placeholder="072 555 5555"
                         expanded
                />
              </b-field>
              <b-field label="Email Address">
                <b-input required type="email" autocomplete="off" icon="email"
                         placeholder="Website" v-model="settingsObject.email_address.value">

                </b-input>
              </b-field>
              <b-field label="Company Registration Information">
                <b-input
                    autocomplete="off"
                    type="textarea"
                    v-model="settingsObject.company_registration_information.value"
                    placeholder="Registration Information"
                />
              </b-field>
            </div>
            <footer class="card-footer mb-4">
              <b-button native-type="submit" type="is-primary" class="card-footer-item">Save</b-button>
            </footer>
          </form>
        </b-collapse>
      </div>
      <div class="container">
        <b-collapse
            class="card has-boxshadow"
            animation="slide"
            aria-id="contentIdForA11y3">
          <template #trigger="props">
            <div
                class="card-header"
                role="button"
            >
              <p class="card-header-title">
                Letterhead
              </p>
              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                </b-icon>
              </a>
            </div>
          </template>

          <form @submit.prevent="submitLetterhead">
            <div class="card-content">
              <b-field label="Logo" class="content">
                <ul>
                  <li>Your logo should be in PNG format</li>
                  <li>Your logo should be no larger than 1mb</li>
                  <li>Click box below to upload image</li>
                </ul>

                <div v-if="previewUrl!==null">
                  <b-field class="pt-3" position="is-centered">
                    <b-button @click="letterheadLogo=null" type="is-warning">Revert Upload</b-button>
                  </b-field>
                </div>
              </b-field>
              <div class="columns">
                <div class="column is-8">
                  <b-upload ref="upload" v-model="letterheadLogo" drag-drop>

                    <section class="section">
                      <div v-if="previewUrl===null && settingsObject.logo_url.value===null "
                           class="content has-text-centered">
                        <p>
                          <b-icon
                              icon="upload"
                              size="is-large"
                          ></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>

                      </div>
                      <b-image v-if="settingsObject.logo_url.value!==null || previewUrl!==null"
                               :responsive="true"
                               :src="previewUrl===null?settingsObject.logo_url.value:previewUrl"
                               :rounded="false"
                               alt="Logo"
                      ></b-image>
                    </section>


                  </b-upload>
                </div>
                <div class="column">
                  <b-field label="Primary Colour">
                    <chrome-picker :value="settingsObject.letterhead_primary_colour.value"
                                   @input="update=>settingsObject.letterhead_primary_colour.value=update.hex8"></chrome-picker>
                  </b-field>
                  <b-field label="Secondary Colour">
                    <chrome-picker :value="settingsObject.letterhead_secondary_colour.value"
                                   @input="update=>settingsObject.letterhead_secondary_colour.value=update.hex8"></chrome-picker>
                  </b-field>
                </div>
              </div>


            </div>
            <footer class="card-footer mb-4">
              <b-button native-type="submit" type="is-primary" class="card-footer-item">Save</b-button>
            </footer>
          </form>
        </b-collapse>
      </div>
    </section>
  </div>
</template>

<script>
import Lookup from "@/models/Lookup";
import Setting from "@/models/Setting";
import checkExtension from "@/mixins/FileTypes";
import Media from "@/models/Media";
import {Chrome} from 'vue-color'

export default {
  name: "Settings.index",
  components: {'chrome-picker': Chrome},
  data() {
    return {
      previewUrl: null,
      search: null,
      loadingFilter: false,
      servicesDataHold: null,
      meta: Object,
      page: 1,
      letterheadLogo: null,
      use_modal: false,
      limit: 20,
      openSettings: [],
      settingsObject:
          {
            country: {
              key: 'country',
              value: null
            },
            street: {
              key: 'street',
              value: null
            }
            ,
            city: {
              key: 'city',
              value: null
            },
            state: {
              key: 'state',
              value: null
            },
            postal_code: {
              key: 'postal_code',
              value: null
            },
            website: {
              key: 'website',
              value: null
            },
            contact_number: {
              key: 'contact_number',
              value: ''
            },
            email_address: {
              key: 'email_address',
              value: null
            },
            company_registration_information: {
              key: 'company_registration_information',
              value: null
            },
            logo_url: {
              key: 'logo_url',
              value: null
            },
            letterhead_primary_colour: {
              key: 'letterhead_primary_colour',
              value: '#0000000'
            },
            letterhead_secondary_colour: {
              key: 'letterhead_secondary_colour',
              value: '#0000000'
            }
          }
      ,
      loadingData: false,
      searchHold: null,
      phase_id: null,
      without_phases: 1,
      loadingPhases: false
    };
  },
  mixins: [checkExtension],

  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

  },
  watch: {
    letterheadLogo(newVal) {
      if (newVal !== null) {
        if (this.checkExtension(newVal, ['png'])) {
          this.previewUrl = URL.createObjectURL(newVal);
        } else {
          this.letterheadLogo = null
        }
      } else {
        this.previewUrl = null
      }
    },
  },
  methods: {

    keepTyping(event, ref) {
      this.$nextTick(() => {
        this.$refs[ref].focus()
      })
    },
    submitPhysicalAddress() {
      this.$store.dispatch('loader/show')
      let updates = []
      let stores = []
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.city, 'id')) {
        updates.push(this.settingsObject.city)
      } else {
        stores.push(this.settingsObject.city)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.street, 'id')) {
        updates.push(this.settingsObject.street)
      } else {
        stores.push(this.settingsObject.street)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.state, 'id')) {
        updates.push(this.settingsObject.state)
      } else {
        stores.push(this.settingsObject.state)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.country, 'id')) {
        updates.push(this.settingsObject.country)
      } else {
        stores.push(this.settingsObject.country)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.postal_code, 'id')) {
        updates.push(this.settingsObject.postal_code)
      } else {
        stores.push(this.settingsObject.postal_code)
      }
      let promises = []
      if (stores.length > 0) {
        promises.push(Setting.BulkStore(stores, true))
      }
      if (updates.length > 0) {
        promises.push(Setting.BulkUpdate(updates, true))
      }
      Promise.all(promises).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    submitContactInformation() {
      this.$store.dispatch('loader/show')
      let updates = []
      let stores = []
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.website, 'id')) {
        updates.push(this.settingsObject.website)
      } else {
        stores.push(this.settingsObject.website)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.contact_number, 'id')) {
        updates.push(this.settingsObject.contact_number)
      } else {
        stores.push(this.settingsObject.contact_number)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.email_address, 'id')) {
        updates.push(this.settingsObject.email_address)
      } else {
        stores.push(this.settingsObject.email_address)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.company_registration_information, 'id')) {
        updates.push(this.settingsObject.company_registration_information)
      } else {
        stores.push(this.settingsObject.company_registration_information)
      }

      let promises = []
      if (stores.length > 0) {
        promises.push(Setting.BulkStore(stores, true))
      }
      if (updates.length > 0) {
        promises.push(Setting.BulkUpdate(updates, true))
      }
      Promise.all(promises).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    async submitLetterhead() {
      this.$store.dispatch('loader/show')
      let updates = []
      let stores = []
      if (this.letterheadLogo !== null) {
        let formData = new FormData();
        formData.append("file", this.letterheadLogo, 'logo');
        formData.append("path", 'images/');
        await Media.StoreByPath(formData).then(({entities: {media}}) => {
          this.settingsObject.logo_url.value = media[0].url
        }).catch(err => {
          this.handleError(err)

        })
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.logo_url, 'id')) {
        updates.push(this.settingsObject.logo_url)
      } else {
        stores.push(this.settingsObject.logo_url)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.letterhead_primary_colour, 'id')) {
        updates.push(this.settingsObject.letterhead_primary_colour)
      } else {
        stores.push(this.settingsObject.letterhead_primary_colour)
      }
      if (Object.prototype.hasOwnProperty.call(this.settingsObject.letterhead_secondary_colour, 'id')) {
        updates.push(this.settingsObject.letterhead_secondary_colour)
      } else {
        stores.push(this.settingsObject.letterhead_secondary_colour)
      }


      let promises = []
      if (stores.length > 0) {
        promises.push(Setting.BulkStore(stores, true))
      }
      if (updates.length > 0) {
        promises.push(Setting.BulkUpdate(updates, true))
      }
      Promise.all(promises).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    syncSettings() {
      Setting.query().get().map(setting => {
        if (Object.hasOwnProperty.call(this.settingsObject, setting.key)) {
          this.settingsObject[setting.key] = setting
        }
      })
      return Promise.resolve()
    },
  },
  computed: {
    settings() {
      return Setting.query().get()
    },
    countries() {
      return Lookup.query().where('lookup_type_id', 4).orderBy('name').get()
    },

  },
  mounted() {
    this.$store.dispatch('loader/show')
    Lookup.FetchAll({page: 1, limit: 9999})
    Setting.FetchAll({page: 1, limit: 999}).then(() => {
      this.syncSettings().then(() => {
        this.$store.dispatch('loader/hide')
      })
    })


  },
};
</script>
