var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadingData)?_c('div',[_c('CampusHeader',{attrs:{"campuses":_vm.campuses,"can-select-year":true,"can-create":!!_vm.$store.getters['entities/user-permissions/find']('create campuses'),"noCampusOverride":_vm.noCampuses,"activeTab":_vm.activeTab,"hide-tabs":true,"header-text":'Campus Admin',"campus":_vm.active_campus,"header_tabs":_vm.header_tabs},on:{"start-creating":_vm.startCreatingCampus,"campus-selected":_vm.selectCampus,"change-tab":_vm.changeTab}}),(!_vm.noCampuses)?_c('section',{staticClass:"section pt-4 box"},[_c('b-tabs',{class:{'hidden-tabs':_vm.header_tabs.length<2},attrs:{"expanded":"","multiline":"","destroy-on-hide":true},on:{"input":_vm.changeTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"label":"Campus Information"}},[_c('div',{staticClass:"columns is-centered "},[_c('div',{staticClass:"column is-12"},[_c('CampusForm',{attrs:{"can-create":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create campuses'
                ),"can-edit":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit campuses'
                ),"can-delete":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete campuses'
                ),"campus":_vm.active_campus,"edit":true},on:{"campus-deleted":_vm.deleteCampus}})],1)])]),(!_vm.noCampuses)?_c('b-tab-item',{attrs:{"label":"Manage Academics"}},[_c('ManageAcademicsWrapper',[_c('div',{staticClass:"column is-4"},[_c('PhasesPanel',{attrs:{"can-edit-level":true,"can-create-level":!!_vm.$store.getters['entities/user-permissions/find']('create levels'),"show-classes":!!_vm.$store.getters['entities/user-permissions/find'](
                  'view homeclass'
                ),"can-delete-class":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete homeclass'
                ),"can-edit-class":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit homeclass'
                ),"can-create-class":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create homeclass'
                ),"canCreatePhase":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create phases'
                ),"canCreateStage":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create stages'
                ),"canEditStage":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit stages'
                ),"canDeletePhase":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete phases'
                ),"canDeleteStage":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete stages'
                ),"canEditPhase":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit phases'
                ),"canSelectPhase":false,"active-campus-id":_vm.activeCampusId,"active_campus":_vm.active_campus,"selected_phase":_vm.selected_phase}})],1),_c('div',{staticClass:"column"},[_c('SubjectsPanel',{attrs:{"show-attendances":true,"canCreateSubject":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create subjects'
                ),"canEditSubject":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit subjects'
                ),"canDeleteSubject":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete subjects'
                ),"canCreateExtramural":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create extramurals'
                ),"canEditExtramural":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit extramurals'
                ),"canDeleteExtramural":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete extramurals'
                ),"canViewExtramural":true,"active-campus-id":_vm.activeCampusId}})],1)])],1):_vm._e(),(!!_vm.$store.getters['entities/user-permissions/find']('view subjects'))?_c('b-tab-item',{attrs:{"label":"Programmes"}},[_c('div',{staticClass:" columns"},[_c('div',{staticClass:"column is-12"},[_c('ProgrammesPanel',{attrs:{"can-create":false,"can-edit":false,"can-delete":false}})],1)])]):_vm._e(),(!!_vm.$store.getters['entities/user-permissions/find']('edit settings'))?_c('b-tab-item',[_c('div',{staticClass:"box columns"},[_c('div',{staticClass:"column is-12"},[_c('SettingsPanel',{attrs:{"can-create":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create settings'
                ),"can-edit":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit settings'
                ),"can-delete":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete settings'
                )}})],1)])]):_vm._e(),(!!_vm.$store.getters['entities/user-permissions/find']('edit services'))?_c('b-tab-item',{attrs:{"label":"Manage Services"}},[_c('div',{staticClass:" columns"},[_c('div',{staticClass:"column is-12"},[_c('ServicesTable',{attrs:{"can-create":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create services'
                ),"can-edit":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit services'
                ),"can-delete":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete services'
                )}})],1)])]):_vm._e(),(!!_vm.$store.getters['entities/user-permissions/find']('edit services'))?_c('b-tab-item',{attrs:{"label":"Manage Packages"}},[_c('div',{staticClass:" columns"},[_c('div',{staticClass:"column is-12"},[_c('PackagesTable',{attrs:{"can-create":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create services'
                ),"can-edit":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit services'
                ),"can-delete":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete services'
                )}})],1)])]):_vm._e(),(!!_vm.$store.getters['entities/user-permissions/find']('edit terms'))?_c('b-tab-item',{attrs:{"label":"Manage Terms"}},[_c('div',{staticClass:" columns"},[_c('div',{staticClass:"column is-12"},[_c('TermsTable',{attrs:{"can-create":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create terms'
                ),"can-edit":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit terms'
                ),"can-delete":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete terms'
                )}})],1)])]):_vm._e(),(!!_vm.$store.getters['entities/user-permissions/find']('edit sports-houses'))?_c('b-tab-item',{attrs:{"label":"Manage Sports Houses"}},[_c('div',{staticClass:" columns"},[_c('div',{staticClass:"column is-12"},[_c('SportsHousesTable',{attrs:{"can-create":!!_vm.$store.getters['entities/user-permissions/find'](
                  'create sports-houses'
                ),"can-edit":!!_vm.$store.getters['entities/user-permissions/find'](
                  'edit sports-houses'
                ),"can-delete":!!_vm.$store.getters['entities/user-permissions/find'](
                  'delete sports-houses'
                )}})],1)])]):_vm._e()],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }