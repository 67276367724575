<template>
  <div class="is-fullwidth">
    <div class="columns">
      <div class="column is-12">
        <b-field grouped>
          <b-field expanded label="Search">
            <!--suppress HtmlUnknownAttribute -->
            <b-input
                v-debounce:300ms="getFilteredServices"
                placeholder="Search"
                :loading="loadingFilter"
                v-model="searchHold"
            ></b-input>
          </b-field>
          <b-field expanded label="Phase">
            <b-select v-model="phase_id" :loading="loadingPhases" expanded placeholder="Phase">
              <option value="All">All</option>
              <option v-for="phase in phases" :key="phase.id" :value="phase.id">{{ phase.name }}</option>
            </b-select>
          </b-field>
          <b-field v-if="phase_id!=='All'" expanded label="Without Phases">
            <b-checkbox v-model="without_phases" :true-value="1" :false-value="0"></b-checkbox>
          </b-field>

          <b-field label="Create" v-if="canCreate">
            <b-button
                @click="startCreatingService()"
                type="is-primary"
                :icon-right="$tc('icons.create')"
            >Create new Service
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <PerfectScrollbarWrapper>
    <b-table
        class="margin-top is-clickable is-fullwidth"
        :data="services"
        :loading="loadingData"
        :striped="true"
        :hoverable="true"
        :bordered="false"
        :scrollable="false"
        @click="viewService"
    >
      <template #empty>
        <div class="has-text-centered">No Services</div>
      </template>
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>

      <b-table-column
          v-slot="props"
          label="Name"
          field="name"
          sortable

      >{{ props.row.name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Phases"

      >{{ props.row.phases.length > 0 ? displayPhases(props.row.phases) : 'None' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Cost"
          field="cost"
          sortable
          numeric
      >{{ props.row.cost }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Adhoc Cost"
          field="adhoc_cost"
          sortable
          numeric
      >{{ props.row.adhoc_cost }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Billing Period"
          field="billing_period"
          sortable
          numeric
      >{{ props.row.billing_period }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Notice Period Type"
          field="notice_period_type"
          sortable
          numeric
      >{{ getTypeName(props.row.notice_period_type) }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Notice Period (days)"
          field="notice_period"
          sortable
          numeric
      >{{ props.row.notice_period }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Cutoff Day"
          field="notice_cut_day"
          sortable
          numeric
      >{{ props.row.notice_cut_day }}
      </b-table-column>
      <!--suppress EqualityComparisonWithCoercionJS -->
      <b-table-column
          v-slot="props"
          label="Has Attendances"
          field="has_attendances"
          sortable
          numeric
      >{{ props.row.has_attendances == true ? 'Yes' : 'No' }}
      </b-table-column>

      <!--suppress EqualityComparisonWithCoercionJS -->
      <b-table-column
          v-slot="props"
          label="Has Adhoc Attendants"
          field="has_ad_hoc_attendants"
          sortable
          numeric
      >{{ props.row.has_ad_hoc_attendants == true ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Is Open"
          field="is_open"
          sortable
          numeric
      >{{ props.row.is_open ? 'Yes' : 'No' }}
      </b-table-column>

      <b-table-column
          v-slot="props"
          label=""
          custom-key="actions"
          centered

      >
        <div v-on:click.stop>
          <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
            <template #trigger>
              <b-icon
                  :icon="$tc('icons.more')"/>
            </template>
            <b-dropdown-item @click="startDelete(props.row)"
                            v-if="canDelete"
                            aria-role="listitem">Delete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-table-column>
    </b-table>
    </PerfectScrollbarWrapper>
    <b-pagination
        class="mt-4"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
    ></b-pagination>
  </div>
</template>

<script>
import Service from "@/models/Service";
import ServiceForm from "@/components/services/ServiceForm";
import Phase from "@/models/Phase";
import {mapState} from "vuex";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "Service.index",
  components:{PerfectScrollbarWrapper},
  data() {
    return {
      search: null,
      loadingFilter: false,
      servicesDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      searchHold: null,
      phase_id: 'All',
      without_phases: 1,
      loadingPhases: false
    };
  },
  props: {
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default: false,
    },

  },
  methods: {
    displayPhases(phases) {

      let names = ''
      phases.map((phase, index) => {
        if (index === 0) {
          names = names + phase.name
        } else {
          names = names + ', ' + phase.name
        }
      })
      return names
    },

    getTypeName(id) {
      return this.$store.state.services.notice_period_types.find(type => type.id === id).name
    },
    startDelete(service) {
      this.$buefy.dialog.confirm({
        title: `Deleting service`,
        confirmText: `Delete service`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this service? Deleting this service will remove it from all users that are currently added to it.`,
        onConfirm: () =>
            Service.Delete(service.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Service deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreatingService() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: ServiceForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    viewService(service) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          service: service,
          campus_id: this.$store.state.campus.selected_campus_id,
        },
        component: ServiceForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    getFilteredServices(text) {
      this.search = text;
    },
    setPage(pageNumber) {
      this.loadingData = true;
      this.servicesDataHold = this.services;
      Service.deleteAll();
      this.page = pageNumber;
      Service.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
          ["teachers"]
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.servicesDataHold = null;
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
    selected_campus_id(newValue) {
      this.loadingPhases = true
      Phase.FetchAll({page: 1, limit: 999}, {campus_id: newValue}).finally(() => {
        this.loadingPhases = false
      })
    },
  },
  computed: {
    ...mapState('campus', ['selected_campus_id']),
    phases() {
      return Phase.query().where('campus_id', this.$store.state.campus.selected_campus_id).where('year', this.$store.state.dates.year).get()
    },
    filters() {
      return {
        ...(this.search
            ? {
              search: this.search,
            }
            : {}),
        without_phases: this.without_phases
        , ...(this.phase_id !== 'All'
            ? {
              phase_id: this.phase_id,
            }
            : {}),
        ...(this.$store.state.campus.selected_campus_id && !this.$store.state.campus.all_campuses
            ? {campus_id: this.$store.state.campus.selected_campus_id}
            : {}), year: this.$store.state.dates.year
      };
    },
    services() {
      if (this.servicesDataHold === null) {
        return Service.query().with("teachers").with('phases').orderBy('name').get();
      } else {
        return this.servicesDataHold;
      }
    },
    edit_services() {
      return this.$store.getters["entities/user-permissions/find"](
          "edit services"
      );
    },
  },
  mounted() {
    this.setPage(this.page, true);
    this.$store.state.documentation.tab_name = 'services'
    this.$store.state.documentation.active_doc_document_path = './Campus Administration/Manage Services.md'

  },
};
</script>
